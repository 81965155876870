:root {
  --primary-bg: #000000;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
}

body {
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0; /* Remove padding */
  box-sizing: border-box;
}

.App img {
  max-width: 90%;  /* Ensure the image doesn't stretch too wide */
  max-height: 90%; /* Ensure the image doesn't stretch too tall */
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
}
